import React, { useState } from 'react';
import axios from 'axios';
import './Page5.css';

function Page5() {
  const [selectedLoc, setSelectedLoc] = useState('kitchen');
  const [selectedAction, setSelectedAction] = useState('on');
  const [selectedScene, setSelectedScene] = useState('Soho');
  const [selectedGroup, setSelectedGroup] = useState('kitchen');
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const lamps = async () => {

    try {
      const response = await axios.post(
        'https://kidr78home.tplinkdns.com/api/lamps',
        {
          action: selectedAction,
          loc: selectedLoc,
        },
        {
          headers: {
            Authorization: 'kSuIlUmNaTiIeO5',
          },
        }
      );
      setResponseMessage(response.data.message || 'Successfully sent!');
    } catch (error) {
      console.error('Error :', error);
      setResponseMessage('Failed to execute. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const scenes = async () => {

     try {
       const response = await axios.post(
         'https://kidr78home.tplinkdns.com/api/lamps',
         {
           scene: selectedScene,
           group: selectedGroup,
         },
         {
           headers: {
             Authorization: 'kSuIlUmNaTiIeO5',
           },
         }
       );
       setResponseMessage(response.data.message || 'Successfully sent!');
     } catch (error) {
       console.error('Error :', error);
       setResponseMessage('Failed to execute. Please try again.');
     } finally {
       setIsLoading(false);
     }
   };

  return (
    <div className="page4-container">
      <div className="device-selector">
        <label htmlFor="locationSelect">Location:</label>
        <select
          id="locationSelect"
          value={selectedLoc}
          onChange={(e) => setSelectedLoc(e.target.value)}
        >
          <option value="kitchen">Kitchen</option>
          <option value="livingroom">Livingroom</option>
        </select>
      </div>

      <div className="device-selector">
        <label htmlFor="actionSelect">Action:</label>
        <select
          id="actionSelect"
          value={selectedAction}
          onChange={(e) => setSelectedAction(e.target.value)}
        >
          <option value="on">On</option>
          <option value="off">Off</option>
        </select>
      </div>

      <button
        onClick={lamps}
        className={isLoading ? 'blinking' : ''}
        disabled={isLoading}
      >
        {isLoading ? 'Processing...' : 'Run Lamps'}
      </button>
      
      <button
        onClick={() => {
            setSelectedScene("Soho");
            setSelectedGroup("Monitors");
            scenes();
        }}
        className="beautiful-button"
      >
        Soxo Monitors
      </button>

      <button
         onClick={() => {
             setSelectedScene("Under the tree");
             setSelectedGroup("Monitors");
             scenes();
         }}
         className="beautiful-button"
       >
         Under the tree Monitors
       </button>


      <button
         onClick={() => {
             setSelectedScene("Color burst");
             setSelectedGroup("Monitors");
             scenes();
         }}
         className="beautiful-button"
       >
         Color burst Monitors
       </button>


      {responseMessage && (
        <div className="response-message">
          {responseMessage}
        </div>
      )}
    </div>
  );
}

export default Page5;

