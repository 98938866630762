// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Page4 from './components/Page4';
import Page3 from './components/Page3';
import Page2 from './components/Page2';
import Page5 from './components/Page5';
import Page6 from './components/Page6';
import CV from './components/CV';
import Login from './components/Login';

function App() {

  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    if (storedLoginStatus) {
      setLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const handleLogout = () => {
    setLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  };

return (
  <Router>
    {isLoggedIn && <Navbar onLogout={handleLogout} />} 
    <Routes>
      {isLoggedIn ? (
	<>
        <Route path="/" element={<Page2 />} />
	    <Route path="/sahko" element={<Page3 />} />
	    <Route path="/cast" element={<Page4 />} />
        <Route path="/lights" element={<Page5 />} />
        <Route path="/cv" element={<CV />} />
        <Route path="/fh" element={<Page6 />} />
	</>
      ) : (
        <Route path="/*" element={<Login onLogin={handleLogin} />} />
      )}
    </Routes>
  </Router>
);
}

export default App;

