// src/components/Navbar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ onLogout }) => {

  return (
    <nav className="navbar">
      <ul className="nav-list">
        <li className="nav-item">
          <NavLink to="/" end className="nav-link nactive">
            Sensors
          </NavLink>
        </li>
	    <li className="nav-item">
          <NavLink to="/sahko" end className="nav-link nactive">
            Sahko
          </NavLink>
        </li>
        <li className="nav-item">
             <NavLink to="/lights" end className="nav-link nactive">
               Lights
             </NavLink>
           </li>
	    <li className="nav-item">
          <NavLink to="/cast" end className="nav-link nactive">
            Cast Text
          </NavLink>
        </li>
        <li className="nav-item">
           <NavLink to="/fh" end className="nav-link nactive">
             FileHandler
           </NavLink>
         </li>
        <li className="nav-item">
          <NavLink to="/cv" className="nav-link nactive">
            CV
          </NavLink>
        </li>

        {/* Add more navigation links as needed */}
      </ul>
      <div>
        <button className="logout-button" onClick={onLogout}>Logout</button>
      </div>
    </nav>
  );
};

export default Navbar;
