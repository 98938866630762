import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Page5.css';

function Page6() {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    if (items.length > 0) {
      setSelectedItem(`${items[0]}`);
    } else {
      setSelectedItem('');
    }
  }, [items]);

  const fetchItems = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        'https://kidr78home.tplinkdns.com/api/fh',
        { action: 'ls', path: '' },
        {
          headers: {
            Authorization: 'kSuIlUmNaTiIeO5',
          },
        }
      );

      console.log('Raw API Response:', response.data);
      let data = response.data;

      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (error) {
          console.error('Failed to parse JSON:', error);
          setResponseMessage('Invalid response format.');
          return;
        }
      }

      if (Array.isArray(data)) {
        const cleanedItems = data.map(item => item.replace(/^"(.*)"$/, '$1'));
        console.log('Processed Items:', cleanedItems);
        setItems(cleanedItems);
      } else {
        console.error('Unexpected response format:', data);
        setResponseMessage('Invalid response format.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setResponseMessage('Failed to fetch items. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchItems2 = async () => {
    if (!selectedItem) {
      setResponseMessage('Please select an item.');
      return;
    }

    console.log('Fetching items for path:', selectedItem);

    setIsLoading(true);
    try {
      const response = await axios.post(
        'https://kidr78home.tplinkdns.com/api/fh',
        { action: 'ls', path: selectedItem },
        {
          headers: {
            Authorization: 'kSuIlUmNaTiIeO5',
          },
        }
      );

      console.log('Raw API Response from fetchItems2:', response.data);
      let data = response.data;

      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (error) {
          console.error('Failed to parse JSON:', error);
          setResponseMessage('Invalid response format.');
          return;
        }
      }

      if (Array.isArray(data)) {
        const cleanedItems = data.map(item => item.replace(/^"(.*)"$/, '$1'));
        console.log('Processed Items from fetchItems2:', cleanedItems);

        setItems(cleanedItems);
      } else {
        console.error('Unexpected response format:', data);
        setResponseMessage('Invalid response format.');
      }
    } catch (error) {
      console.error('Error fetching data from fetchItems2:', error);
      setResponseMessage('Failed to fetch items. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const copyItem = async () => {
    if (!selectedItem) {
      setResponseMessage('Please select an item to copy.');
      return;
    }

    console.log('Copying item:', selectedItem);

    setIsLoading(true);
    try {
      const response = await axios.post(
        'https://kidr78home.tplinkdns.com/api/fh',
        { action: 'copy', path: selectedItem },
        {
          headers: {
            Authorization: 'kSuIlUmNaTiIeO5',
          },
        }
      );

      console.log('Copy API Response:', response.data);
      setResponseMessage(`Successfully copied: ${selectedItem}`);
    } catch (error) {
      console.error('Error copying item:', error);
      setResponseMessage('Failed to copy item. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="page4-container">
      <div className="device-selector">
        <label htmlFor="itemSelect">Select an Item:</label>
        <select
          id="itemSelect"
          value={selectedItem}
          onChange={(e) => setSelectedItem(`${e.target.value}`)}
        >
          {items.length === 0 ? (
            <option value="" disabled>Loading...</option>
          ) : (
            items.map((item, index) => (
              <option key={index} value={item}>{item}</option>
            ))
          )}
        </select>
        <button onClick={fetchItems2}>Select</button>
        <button onClick={copyItem}>Copy</button>
      </div>

      {isLoading && <p>Loading...</p>}
      {responseMessage && <div className="response-message">{responseMessage}</div>}
    </div>
  );
}

export default Page6;

